<template>
  <div class="modal fade" id="linkPaymentInvoice" tabindex="-1" role="dialog" aria-labelledby="linkPaymentInvoiceBody"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-large" role="document">
      <div class="modal-content">
        <div class="modal-body form-material p-5" v-if="ready" id="linkPaymentInvoiceBody">
            <div class="form-row">  
                <div class="form row col-md-12">
                    <input-field
                        class="col-md-3"
                        :field="supplierField"
                        :defValue.sync="supplierId"
                        @change="getInvoices"
                    ></input-field>
                    <input-field
                        class="col-md-3"
                        :field="currencyField"
                        :defValue.sync="currencyId"
                        @change="getInvoices"
                    ></input-field>

                </div>
                <div class="col-md-6">
                    <table class="table table-striped  table-sm table-bordered">
                        <thead>
                            <tr>
                                <th>Factura</th>
                                <th>Monto</th>
                                <th>Moneda</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="invoice in invoices">
                                <td>{{invoice.InvoiceNumber}}</td>
                                <td class="text-right">{{toNumber(invoice.Total)}} {{invoice.CurrencyId}}</td>
                                <td><input type="checkbox" v-model="invoice.selected"
                                    :disabled="invoice.disabled"
                                    @change="invoiceSelected(invoice)"></td>
                            </tr>
                        </tbody>
                        <tfoot class="font-weight-bold">
                            <tr>
                                <td colspan="2">Total</td>
                                <td class="text-right">{{toNumber(totalInvoices)}} {{currencyId}}</td>
                            </tr>
                            </tfoot>
                    </table>
                </div>
                <div class="col-md-6">
                    <table class="table table-striped  table-sm table-bordered">
                        <thead>
                            <tr>
                                <th>Pago</th>
                                <th>Monto</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="payment in payments">
                                <td>{{payment.id}}</td>
                                <td class="text-right">{{toNumber(payment.PaymentAmount)}} {{payment.PaymentCurrencyId}}</td>
                            </tr>
                        </tbody>
                        <tfoot class="font-weight-bold">
                            <tr>
                                <td>Total</td>
                                <td class="text-right">{{toNumber(totalPayments)}} {{currencyId}}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">{{tr('Close')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'link-payment-invoice',
    mixins: [escape],
    props: ['show', 'report', 'payments'],
    data () {
        return {
            ready: false,
            supplierId: null,
            currencyId: null,
            services: [],
            printing: false,
            supplierField: {
                name: 'SupplierId',
                editor: 'vue-select',
                relation: 'supplier',
                label: 'Supplier',
                optionLabels: 'Name'
            },
            currencyField: {
                name: 'CurrencyId',
                editor: 'vue-select',
                relation: 'currency',
                label: 'Currency',
                optionLabels: 'id'
            },
            invoices: [],
        }
    },
    computed: {
        totalPayments () {
            return _.sumBy(this.payments, (r) => r.PaymentAmount);
        },
        totalInvoices () {
            return _.sumBy(this.invoices, (r) => {
                if (r.selected) return r.Total;
                return 0;
            });
        }
    },
    async mounted () {
        $('#linkPaymentInvoice').modal({backdrop: 'static', keyboard: false}, 'show');
        if (this.payments && this.payments.length>0) {
            this.supplierId = this.payments[0].SupplierId;
            this.currencyId = this.payments[0].PaymentCurrencyId;
            this.getInvoices();
        }
        this.ready = true;
    },
    methods: {
        close () {
            this.$emit('update:show', false);
            $('#linkPaymentInvoice').modal('hide');
        },
        toNumber (value) {
            return tools.toNumber(value, 2);
        },
        async getInvoices () {
            if (!this.supplierId || !this.currencyId) return;
            let res = await api.get('/api/purchaseinvoice/', {
                filters: JSON.stringify(
                    {SupplierId: this.supplierId, CurrencyId: this.currencyId}
                )
            });
            if (res) {
                this.invoices = res.map((r)=> Object.assign({selected: false, disabled: false}, r));
            }
        },
        invoiceSelected (invoice) {
            if (this.payments.length>1) {
                this.invoices.forEach((r)=> {
                    if (invoice.selected && r.id!=invoice.id) r.disabled = true;
                    if (!invoice.selected) r.disabled = false;
                });
                this.invoices = Object.assign([], this.invoices);
            }
        }
    }
}
</script>

