const modules = vueTools.importModule('modules', 'tools', 'custom').default;

modules.settingsMenu.endpoints.push('bookingsetting')
modules.reportsMenu.endpoints.push({endpoint: 'report', label: 'Operation Status', componentName: 'OperStatusReport', report: 'operstatus'})
modules.settingsMenu.endpoints.push('odstext')
modules.settingsMenu.endpoints.push('ods')
modules.reportsMenu.endpoints.push({endpoint: 'report', label: 'Management Summary', componentName: 'ManagementSummaryReport', report: 'managementreport'})
modules.adminMenu.endpoints.push({endpoint: 'report' , label: 'Listado de Salida de Fondos', componentName: 'CashOutReport', report:'cash-out'})
//modules.adminMenu.endpoints.push({endpoint: 'import-invoices' , label: 'Importar Facturas'}) 

export default modules;
